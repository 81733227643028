




































































.ds-about {
  margin-top: 100px;
  text-align: left;
  &-card {
    border: 0;
    color: rgb(255, 255, 255);
    &-li {
      color: rgb(221, 221, 221);
      line-height: 24px;
      font-size: 18px;
    }
  }
}
